import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { ControllersService } from './controllers.service';
import { EventService } from './event-service.service';
import { ResolverService } from './resolver.service';
import { SharedService } from './shared.service';

function _window(): any {
  // return the global native browser window object
  return window;
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  response: any = {};
  buyerInfo: any;
  userData:any;
  constructor(
    public event: EventService,
    public resolver: ResolverService,
    public controller: ControllersService,
    private router: Router,
    public navCtrl: NavController,
    private location: Location,
    private shared : SharedService
  ) {}

  /**
   * return logout user
   */
  public goToLogin() {
    this.navCtrl.navigateRoot(['/loginRegistration']);
    if (localStorage.getItem('session_expired')) {
      this.controller.presentAlert(
        'Your session has expired. Please login to continue',
        'Session Expired'
      );
      window.localStorage.clear();
      window.sessionStorage.clear();
      this.resolver.guestLogin().subscribe((data: any) => {
        localStorage.setItem('auth', JSON.stringify(data));
        localStorage.setItem('guest', 'true');
      });
    }
  }

  /**
   *
   * @param data
   * @returns
   */

  public regUser(data) {
    return this.resolver.postAuth('presentOrNot', data);
  }

  /**
   *
   * @param data
   */
  public loginUser(data) {
    this.controller.presentLoading('Logging you in...', 5000);
    this.resolver.postAuth('login', data).subscribe(
      (data) => (this.response = data),
      (err) => {
        this.controller.loadingController.dismiss().then((data) => {
          this.controller.presentAlert(
            'Wrong email or password. Please try again'
          );
        });
      },
      () => {
        localStorage.clear();
        this.storeCredentials(this.response);
        this.getBuyerInfo(true);

        // this.fetchSellerProfile();
      }
    );
  }
  signUpWithGoogle(data) {
    this.controller.presentLoading('Signing you up...');
    return this.resolver.postAuth('google/signup', data);
  }
  loginWithGoogle(email: string) {
    this.controller.presentLoading('Logging you in...', 5000);
    return this.resolver.postAuth('google/login', { email: email });
  }

  /**
   *
   * @param data
   */
  public changePassword(data) {
    this.controller.presentLoading('Updating password...');
    this.resolver.patchAuth('change-password', data).subscribe(
      (data) => (this.response = data),
      (err) => {
        this.controller.loadingController.dismiss();
        this.controller.presentAlert(
          'Either the token has expired or something went wrong. Please try the change password process again.'
        );
      },
      () => {
        this.controller.loadingController.dismiss();
        this.controller.presentAlert(
          `The password has been successfully updated`,
          'Success'
        );
      }
    );
  }
  /**
   *
   * @param data
   * @description verifying the email token
   */
  public verifyEmail(data) {
    this.resolver.postAuth('verify-email', data).subscribe(
      (data) => (this.response = data),
      (err) => {
        if (err.status == 406) {
          this.controller.presentAlert(
            'The token has either expired or is invalid. Please try again'
          );
          this.router.navigate(['/login']);
        }
      },
      () => {
        this.controller.presentAlert(
          'The email has been successfully verified. Please login in order to continue your journey',
          'Success'
        );
        this.router.navigate(['/login']);
      }
    );
  }
  /**
   *
   * @param data
   * @description Stores the response sent back from the login API containing the token
   */
  public storeCredentials(data) {
    window.localStorage.setItem('auth', JSON.stringify(data));
  }
  /**
   *
   * @param data
   * @returns The stored JSON containing the token and other values (if any)
   */
  public static fetchCredentials() {
    return JSON.parse(window.localStorage.getItem('auth'));
  }
  /**
   *
   * @returns
   */
  public static fetchUserInfo() {
    let userInfo = JSON.parse(window.localStorage.getItem('profile'));
    if (!userInfo) {
      window.location.replace('/login');
    }
    return userInfo;
  }
  /**
   *
   * @param data
   */
  public storeUserInfo(data) {
    window.localStorage.setItem('profile', JSON.stringify(data));
  }

  /**
   * @returns Fetches / Stores the user details  based on the token
   */
  public fetchSellerProfile() {
    this.resolver.getSellerInfo().subscribe(
      (data) => (this.response = data),
      (err) => {
        console.warn(err);
      },
      () => {
        this.controller.loadingController.dismiss();
        // this.response.role = "businessowner";
        this.router.navigate(['/profile/seller']);
      }
    );
  }

  public updateBuyerInfo(data, returnBack: boolean = false) {
    this.controller.presentLoading('Updating profile..');
    this.resolver.saveBuyerInfo(data).subscribe(
      (data) => (this.response = data),
      (err) => console.warn(err),
      () => {
        this.controller.presentToast(
          'The buyer profile has been successfully updated.'
        );
        this.controller.loadingController.dismiss();
        this.getBuyerInfo(true, returnBack);

        //this.router.navigate(['/tab1'])
      }
    );
  }

  /**
   *
   * @param currentRole
   */
  public changeRole(currentRole: number) {
    // this.controller.presentLoading('Switching role...');
    this.resolver.changeRole(currentRole).subscribe(
      (data) => (this.response = data),
      (err) => console.warn(err),
      () => {
        this.controller.presentToast(
          `You have been switched to ${currentRole == 3 ? 'Buyer' : 'Seller'}`
        );
        // this.controller.loadingController.dismiss();
        this.getBuyerInfo(false);
      }
    );
  }

  public getBuyerInfo(
    redirect: boolean = true,
    returnBack: boolean = false,
    social: boolean = false
  ) {
    this.resolver
      .getBuyerInfo()
      .toPromise()
      .then((data: any) => {
        this.buyerInfo = data;
        let urlData= localStorage.getItem('Previous-url2');

        window.localStorage.setItem('temp', JSON.stringify(this.buyerInfo));
        this.event.publish({ roleSwitched: true });
        if (redirect) {
          this.controller.loadingController.dismiss();
          if (returnBack) {
            this.location.back();
            return;
          }
          if (this.buyerInfo.dateOfBirth && !social) {
            if (urlData) {
              this.router.navigate([`/${urlData}`]);
             }else{
              this.router.navigate(['']);
             }
          } else {
            // this.router.navigate(['/profile/buyer']);
           if (urlData) {
            this.router.navigate([`/${urlData}`]);
           }else{
            this.router.navigate(['']);
           }
          }
        }
        localStorage.removeItem('guest');
      })
      .catch((err) => {
        this.controller.presentAlert(err.error.error.message);
      })
      .finally(() => {});
  }
  public logout() {
    window.localStorage.clear();
    window.sessionStorage.clear();
    //  localStorage.setItem('guest',"true");
    //    this.router.navigate(['/reg']);
    //  this.router.navigate(['/account']);
    window.location.reload();
  }

  public getProfile() {
    return JSON.parse(window.localStorage.getItem('temp'));
  }
  get nativeWindow(): any {
    return _window();
  }
  isuserLoggedIn(){
   return this.shared.isGuestMode();
  }
  isOnlyUserReg(){
   let userRoll = localStorage.getItem('temp');
   this.userData = JSON.parse(userRoll);
    return this.userData?.currentRole == 2;
  }
}
