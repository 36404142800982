import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './services/auth.intercepter';
import {SocialAuthServiceConfig ,GoogleLoginProvider, FacebookLoginProvider, SocialAuthService,} from '@abacritt/angularx-social-login';
import { ClipboardModule } from 'ngx-clipboard';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { NgxOtpInputModule } from 'ngx-otp-input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderModule } from './utils/loader/loader.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { TabsPageModule } from './tabs/tabs.module';
import { SharedModule } from './shared/shared.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBSTJ2_sWlO_mkEvGT5jcTj3lVFB7Fe4Mk',
  authDomain: 'Shopyglam-b9b12.firebaseapp.com',
  projectId: 'Shopyglam-b9b12',
  storageBucket: 'Shopyglam-b9b12.appspot.com',
  messagingSenderId: '1061900658847',
  appId: '1:1061900658847:web:ce5cb1e7434e8b33fd5aaa',
  measurementId: 'G-RJRM2MQDEH',
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    IonicModule.forRoot(),
    HttpClientModule,
    ClipboardModule,
    NgxOtpInputModule,     // Require for the OTP verify
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TabsPageModule,
    SharedModule,
    NgxSkeletonLoaderModule,
    LoaderModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    SocialAuthService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              /*  environment.google,
                     {
                      scope: `https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/user.birthday.read  https://www.googleapis.com/auth/user.gender.read https://www.googleapis.com/auth/user.phonenumbers.read`,
                      plugin_name: "temp"
                    }*/
              '624796833023-clhjgupm0pu6vgga7k5i5bsfp6qp6egh.apps.googleusercontent.com'
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('561602290896109'),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  entryComponents: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
