// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiURL: 'https://api-qa.myshopyglam.com',
  feURL: 'https://qa.myshopyglam.com',
  meeshoApiUrl: 'https://proxy-qa.myshopyglam.com/api/v1/product',
  google:
    '1061900658847-bdc04e2tes98r44vtmcibcn2dgdi2jaa.apps.googleusercontent.com',
  scopes: {
    google: `https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/user.birthday.read  https://www.googleapis.com/auth/user.gender.read https://www.googleapis.com/auth/user.phonenumbers.read`,
  },
  stripeKey:'pk_test_51NBZzVSDVhWF0ZcLDN6rkts4lC2c7TSMJ3Pw8j1q8Vw4y2XTlQzC9Qp9Ags026sJI4qvvfDmUWB5YxhtioKoVG7F00shwYvEXX',
  disputeNo: '918169565911'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
