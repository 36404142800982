import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { OtpPageRoutingModule } from './otp-routing.module';
import { OtpPage } from './otp.page';
import { NgxOtpInputModule } from 'ngx-otp-input';
import { Tab3PageModule } from 'src/app/tab3/tab3.module';
@NgModule({
  declarations: [
    OtpPage
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NgxOtpInputModule,
    OtpPageRoutingModule,
    Tab3PageModule
  ],
})
export class OtpPageModule {
  constructor(){}
}
