import { Component } from '@angular/core';
import { ViewWillEnter } from '@ionic/angular';
import { AuthService } from '../services/auth.service';
import { EventService } from '../services/event-service.service';
import { SharedService } from '../services/shared.service';
import { CartService } from 'src/app/services/cart.service';
import { ResolverService } from '../services/resolver.service';
@Component({
  selector: 'app-tabs',
  templateUrl: 'tabs.page.html',
  styleUrls: ['tabs.page.scss'],
})
export class TabsPage implements ViewWillEnter {
  tabs: Array<any> = [
    {
      name: 'home',
      icon: 'home-outline',
      tab: '/',
      img: false,
    },
    {
      name: 'search',
      icon: 'search-outline',
      img: false,
      tab: 'search/explore',
    },
    {
      name: 'seller',
      icon: 'assets/icon/seller-reg.svg',
      img: true,
      tab: 'seller',
    },
    {
      name: 'bag',
      icon: 'bag-outline',
      img: false,
      tab: 'bag',
    },
    {
      name: 'person-circle',
      icon: 'person-circle-outline',
      img: false,
      tab: 'account',
    },
  ];
  isActive: number = 0;
  sellerTabEnabled: boolean = false;
  constructor(
    public auth: AuthService,
    public event: EventService,
    private shared: SharedService,
    public cart: CartService,
    public resolver: ResolverService,
  ) {
      this.resolver.isHomeActive.subscribe((data)=>{ this.isActive = data; })
    this.event.event.subscribe((data) => {
      if (data.roleSwitched) {
        this.enableSellerDashboard();
      }
    });
  }
  ionViewWillEnter(): void {
    this.enableSellerDashboard();
  }
  public buttonState(index: number) {
    this.isActive = index;
    this.enableSellerDashboard();
    if (index == 0) {
      this.resolver.isGetPostData.next(true);
    }
  }
  public enableSellerDashboard() {
    localStorage.removeItem('Previous-url2');
    let profile = JSON.parse(window.localStorage.getItem('temp'));
    if (this.shared.isGuestMode()) {
      this.tabs[2].tab = 'profile/seller/intro';
    }
    if (profile && profile.maxRole == 3) {
      this.tabs[2].tab = 'profile/seller/intro';
    }
    if (profile?.maxRole == 2) {
      this.tabs[2].tab = 'dashboard/seller';
    }
  }
}
